import { AnalyticsProvider } from '@lemonade-hq/boutique';
import type { FC, PropsWithChildren } from 'react';
import { useCurrentPrincipal } from './EnsurePrincipal';

export const EnsureAnalytics: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { operator, principal } = useCurrentPrincipal();
    const operatorId = operator?.id ?? principal?.subject ?? '';

    return (
        <AnalyticsProvider operatorId={operatorId} segmentKey={import.meta.env.VITE_SEGMENT_KEY}>
            {children}
        </AnalyticsProvider>
    );
};
