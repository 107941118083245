import type { MutationFunction, MutationKey, QueryKey, UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export interface UsePessimisticMutation<TResult, TVariables> {
    readonly mutationFn: MutationFunction<TResult, TVariables>;
    readonly invalidateKeys: QueryKey[];
    readonly onSuccess?: (data: TResult, variables: TVariables) => void;
    readonly onError?: (error: unknown) => void;
    readonly mutationKey?: MutationKey;
}

/**
 * A custom hook for creating pessimistic mutations.
 *
 * This function wraps the `useMutation` hook from `@tanstack/react-query` and adds
 * automatic query invalidation after the mutation settles.
 *
 * @param args - An object containing the mutation configuration:
 *   - mutationFn: The function to be executed during the mutation.
 *   - invalidateKeys: An array of query keys to be invalidated after the mutation.
 *   - onSuccess: Optional callback to be executed on successful mutation.
 *   - onError: Optional callback to be executed on mutation error.
 *   - mutationKey: Optional key for the mutation.
 *
 * @returns A `UseMutationResult` object from `@tanstack/react-query`.
 */
export function usePessimisticMutation<TResult, TVariables>(
    args: UsePessimisticMutation<TResult, TVariables>
): UseMutationResult<TResult, unknown, TVariables, null> {
    const { mutationFn, invalidateKeys, onSuccess, onError, mutationKey } = args;
    const queryClient = useQueryClient();

    return useMutation<TResult, unknown, TVariables, null>({
        mutationFn,
        mutationKey,
        onSettled: () => {
            invalidateKeys.forEach(key => {
                void queryClient.invalidateQueries({ queryKey: key });
            });
        },
        onSuccess,
        onError,
    });
}

/**
 * @deprecated
 * Use `usePessimisticMutation` instead.
 */
export function usePessimisticMutationLegacy<TVariables, TResult>(
    qFunction: MutationFunction<TResult, TVariables>,
    invalidateKeys: QueryKey[],
    onSuccess?: (data: TResult, variables: TVariables) => void,
    onError?: (error: unknown) => void
): UseMutationResult<TResult, unknown, TVariables, null> {
    return usePessimisticMutation<TResult, TVariables>({
        mutationFn: qFunction,
        invalidateKeys,
        onSuccess,
        onError,
    });
}
