import type { Locale } from '@lemonade-hq/lemonation';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { getBoardGroup, getCard } from '../apis/MacrosAPI';
import type { MacrosBoardGroup, MacrosCard, MacrosSupportedEntity, MacrosSupportedProductType } from '../types';

enum MacrosKey {
  GetBoardGroup = 'GET_BOARD_GROUP',
  GetCard = 'GET_CARD',
}

export function useMacrosBoardGroup({
  entityType,
  locale,
  productType,
}: {
  readonly entityType: MacrosSupportedEntity;
  readonly locale: Locale;
  readonly productType: MacrosSupportedProductType;
}): UseQueryResult<MacrosBoardGroup> {
  return useQuery({
    queryKey: [MacrosKey.GetBoardGroup, entityType, locale, productType],
    queryFn: async () => await getBoardGroup({ entityType, locale, productType }),
  });
}

export const useGetCard = (cardId: string): UseQueryResult<MacrosCard> => {
  return useQuery({
    queryKey: [MacrosKey.GetCard, cardId],
    queryFn: async () => await getCard(cardId),
  });
};
