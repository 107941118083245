import { forwardRef } from 'react';
import { Layout } from '../../base/Layout/Layout';
import type { BannerProps } from '../Banner/Banner';
import { Banner } from '../Banner/Banner';

type AiBannerProps = Omit<BannerProps, 'iconName' | 'variant'>;

export const AiBanner = forwardRef<HTMLDivElement, AiBannerProps>((props, ref) => (
  <Layout ref={ref}>
    <Banner {...props} iconName="spark-solid" variant="ai" />
  </Layout>
));
