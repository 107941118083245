import { copyToClipboard, MentionTag, StyledErrorMessage, themedColor } from '@lemonade-hq/bluis';
import type { CustomEditor, RichTextEditorExternalAPI } from '@lemonade-hq/cdk';
import { RichTextEditor as CdkRichTextEditor, Editable, font, getPlainText } from '@lemonade-hq/cdk';
import { forwardRef, memo, useCallback, useRef, useState } from 'react';
import type { Descendant } from 'slate';
import type { EditableProps } from 'slate-react/dist/components/editable';
import styled, { css } from 'styled-components';
import type { MacrosCard, MacrosInitProps } from '../Macros';
import { useMentions } from './hooks/useMentions';
import { MentionsDropdown } from './MentionsDropdown';
import { Toolbar } from './Toolbar';
import type { ToolbarEditButtons } from './types';

const Paper = styled.div<{ readonly bordered: boolean }>`
  text-align: left;
  background: ${themedColor('componentBackground')};
  border-radius: 4px;

  ${({ bordered }) =>
    bordered &&
    css`
      border: 1px solid ${themedColor('inputBorder')};
    `}
`;

const StyledEditable = styled(Editable)`
  overflow: auto;
  ${font('main', { fontSize: '14px', lineHeight: '18px' })}
  color: ${themedColor('primaryText')};
  padding: 20px 30px;
  min-height: 138px !important;
  width: 100%;

  ${({ readOnly }) =>
    readOnly === true &&
    css`
      cursor: not-allowed;
    `}

  a {
    text-decoration: underline;
    color: ${themedColor('primary')};
    cursor: pointer;
  }

  p {
    margin: 0;
  }
`;

const CustomErrorMessage = styled(StyledErrorMessage)`
  margin: 6px 6px 6px 16px;
`;

export const DEFAULT_BORDERED_STATE = true;

export interface RichTextEditorProps {
  readonly autoFocus?: boolean;
  readonly bordered?: boolean;
  readonly className?: string;
  readonly toolbarClassName?: string;
  readonly customFooter?: React.ReactNode;
  readonly disabled?: boolean;
  readonly editor: CustomEditor;
  readonly macrosInitProps?: MacrosInitProps;
  readonly maxLength?: number;
  readonly onAttachmentTag?: () => void;
  readonly onBlur?: () => void;
  readonly onChange: (value: Descendant[]) => void;
  readonly onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  readonly onFocus?: () => void;
  readonly onSelectMacro?: (macros: MacrosCard) => void;
  readonly placeholder?: string;
  readonly showMentions?: boolean;
  readonly showToolbar?: boolean;
  readonly spellCheck?: boolean;
  readonly toolbarEndComponent?: React.ReactNode;
  readonly toolbarButtons?: ToolbarEditButtons[];
  readonly unsavedWarning?: string;
  readonly value: Descendant[];
  readonly allowCopyToClipboard?: boolean;
}

export const RichTextEditor = memo(
  forwardRef<HTMLDivElement, RichTextEditorProps>(
    (
      {
        showToolbar = true,
        bordered = DEFAULT_BORDERED_STATE,
        customFooter,
        className,
        toolbarClassName,
        macrosInitProps,
        onAttachmentTag,
        onSelectMacro,
        toolbarEndComponent,
        showMentions = true,
        disabled,
        maxLength,
        allowCopyToClipboard,
        value,
        toolbarButtons,
        ...props
      },
      ref,
    ) => {
      const [macroErrorMessage, setMacroErrorMessage] = useState<string | undefined>(undefined);
      const richTextEditorAPIRef = useRef<RichTextEditorExternalAPI>(null);

      const {
        handleMentionsKeyboardNavigation,
        handleMentionsKeyboardUp,
        insertMention,
        mentionProps,
        setMentionProps,
        selectedMentionIndex,
        users,
        isLoading,
      } = useMentions(props.editor, { enabled: showMentions });

      const shouldShowMentions = showMentions && mentionProps.isSearching && (users.length > 0 || isLoading);
      const render = useCallback(
        (editableProps: EditableProps) => {
          const copy = (): void => {
            copyToClipboard(getPlainText(value));
          };

          return (
            <Paper bordered={bordered}>
              <StyledEditable className={className} readOnly={disabled} ref={ref} {...editableProps} />
              {customFooter != null && <div>{customFooter}</div>}
              {showToolbar && (
                <>
                  <Toolbar
                    className={toolbarClassName}
                    copyToClipboard={allowCopyToClipboard ? () => copy() : undefined}
                    customEndComponent={toolbarEndComponent}
                    disabled={disabled}
                    macrosInitProps={macrosInitProps}
                    maxLength={maxLength}
                    onAttachmentTag={onAttachmentTag}
                    onMacrosError={setMacroErrorMessage}
                    onSelectMacro={onSelectMacro}
                    toolbarButtons={toolbarButtons}
                  />

                  {macroErrorMessage !== undefined && <CustomErrorMessage>{macroErrorMessage}</CustomErrorMessage>}
                </>
              )}
              {shouldShowMentions && (
                <MentionsDropdown
                  baseStyles={mentionProps.baseStyles}
                  isLoading={isLoading}
                  onCloseDropdown={() => {
                    richTextEditorAPIRef.current?.closeMentionsDropdown();
                  }}
                  onSelect={user => {
                    insertMention(user);
                    richTextEditorAPIRef.current?.closeMentionsDropdown();
                  }}
                  searchQuery={mentionProps.searchQuery}
                  selectedIndex={selectedMentionIndex}
                  users={users}
                />
              )}
            </Paper>
          );
        },
        [
          bordered,
          className,
          disabled,
          ref,
          customFooter,
          showToolbar,
          toolbarClassName,
          allowCopyToClipboard,
          toolbarEndComponent,
          macrosInitProps,
          maxLength,
          onAttachmentTag,
          onSelectMacro,
          toolbarButtons,
          macroErrorMessage,
          shouldShowMentions,
          mentionProps.baseStyles,
          mentionProps.searchQuery,
          isLoading,
          selectedMentionIndex,
          users,
          value,
          insertMention,
        ],
      );

      return (
        <CdkRichTextEditor
          {...props}
          customRenderers={{
            mention: MentionTag,
          }}
          disabled={disabled}
          onKeyDown={showMentions ? handleMentionsKeyboardNavigation : undefined}
          onKeyUp={showMentions ? handleMentionsKeyboardUp : undefined}
          onMentionChange={showMentions ? setMentionProps : undefined}
          ref={richTextEditorAPIRef}
          value={value}
        >
          {render}
        </CdkRichTextEditor>
      );
    },
  ),
);
