import { ArrowIcon, BlenderIcon, CloseIcon, LemonadeIcon } from '@lemonade-hq/bluis';
import { Alink, ClientSideLink, LinkComp } from '@lemonade-hq/bluiza';
import { font, themedColor } from '@lemonade-hq/boutique';
import { Flex, useClickOutside, useCollapsible } from '@lemonade-hq/cdk';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSideMenuSchema } from './queries';
import type { SideMenuItem, SubMenuItem } from './types';
import {
    findCurrentActiveItem,
    saveLastOpenedGroup,
    SideMenuContext,
    SideMenuIconsDictionary,
    useSideMenuContext,
} from './utils';

const TitleIconsContainer = styled(Flex)`
    align-items: center;
    gap: 14px;
`;

const Container = styled.div<{ readonly open: boolean }>`
    position: fixed;
    top: 0;
    left: ${({ open }) => (open ? '0' : '-280px')};
    width: 260px;
    height: 100vh;
    z-index: 101;
    background-color: ${themedColor('componentBackground')};
    padding: 24px 0;
    border-right: 1px solid ${themedColor('separator')};
    overflow: auto;
    transition: left 0.3s ease-in-out;

    ${ClientSideLink}, ${Alink} {
        color: ${themedColor('primaryText')};

        &:hover {
            color: ${themedColor('primary')};
        }
    }
`;

const StyledCloseIcon = styled(CloseIcon)`
    margin-left: auto;
    cursor: pointer;
`;

const iconStyles = { width: 24, height: 24 };

const StyledArrowIcon = styled(ArrowIcon)<{ readonly open: boolean }>`
    margin-left: auto;
    height: 8px;
    width: 8px;
    transform: rotate(${({ open }) => (open ? '180deg' : '0deg')});
    transition: transform 0.3s ease-in-out;
`;

const Header = styled(Flex)`
    padding: 8px 24px;
    align-items: center;
    justify-content: space-between;
`;

const SubMenuContainer = styled.div`
    margin-top: 10px;
`;

const SubMenuTitle = styled.div`
    color: ${themedColor('secondaryText')};
    ${font('main', { fontSize: '10px', lineHeight: '12px', fontWeight: 700 })};
    text-transform: uppercase;
    padding: 6px 0;
    white-space: nowrap;
`;

const SubMenuTitleContainer = styled(Flex)`
    align-items: center;
    width: 100%;
    gap: 6px;
    padding: 0 5px;
`;

const SubMenuTitleLine = styled.div<{ readonly isEnding?: boolean }>`
    border-top: 1px solid ${themedColor('separator')};
    height: 2px;
    width: ${({ isEnding }) => (isEnding ? '100%' : '20px')};
`;

const SubMenuGroup = styled.div`
    background-color: ${themedColor('disabledBackground')};
`;

const GroupItemTitleContainer = styled.div<{ readonly open?: boolean; readonly isActive?: boolean }>`
    padding: 8px 24px;
    gap: 12px;
    display: flex;
    align-items: center;
    color: ${({ isActive }) => (isActive ? themedColor('primary') : themedColor('primaryText'))};
    background-color: ${({ open }) => (open ? themedColor('disabledBackground') : 'transparent')};
    cursor: pointer;

    &:hover {
        background-color: ${themedColor('lightBackground')};
    }
`;

const ItemLinkContainer = styled.div<{
    readonly open?: boolean;
    readonly isActive?: boolean;
    readonly isSingleItem?: boolean;
}>`
    position: relative;
    padding: 8px 24px ${({ isSingleItem }) => (isSingleItem ? '' : '8px 42px')};
    gap: 12px;
    display: flex;
    align-items: center;
    color: ${themedColor('primaryText')};
    background-color: ${({ open }) => (open ? themedColor('disabledBackground') : 'transparent')};
    cursor: pointer;

    &:hover {
        color: ${themedColor('primary')};
        background-color: ${({ isSingleItem }) => (isSingleItem ? themedColor('lightBackground') : 'transparent')};
    }

    ${({ isActive }) =>
        isActive &&
        css`
            color: ${themedColor('primary')};
            font-weight: 700;
        `}
`;

const ActiveItemHighlight = styled.div`
    position: absolute;
    left: 4px;
    width: 3px;
    height: 28px;
    background-color: ${themedColor('primary')};
    border-radius: 5px;
`;

interface ItemLinkProps {
    readonly item: SideMenuItem;
    readonly isSingleLinkItem?: boolean;
    readonly onClick?: () => void;
}

const ItemLink: React.FC<React.PropsWithChildren<ItemLinkProps>> = ({ item, isSingleLinkItem, onClick }) => {
    const { activeItem } = useSideMenuContext();
    const isActive = activeItem != null && 'link' in activeItem && 'link' in item && activeItem.link === item.link;

    const { name } = item;
    const link = 'link' in item ? item.link : '';
    const ItemIcon = name in SideMenuIconsDictionary ? SideMenuIconsDictionary[name] : null;

    return (
        <LinkComp key={link} url={link}>
            <ItemLinkContainer isActive={isActive} isSingleItem={isSingleLinkItem} key={link} onClick={onClick}>
                {isSingleLinkItem && ItemIcon && <ItemIcon style={iconStyles} />}
                {isActive && <ActiveItemHighlight />}
                <div>{name}</div>
            </ItemLinkContainer>
        </LinkComp>
    );
};

interface SubGroupProps {
    readonly group: SubMenuItem;
    readonly mainGroupName: string;
}

const SubGroup: React.FC<React.PropsWithChildren<SubGroupProps>> = ({ group, mainGroupName }) => {
    const { handleItemLinkClick } = useSideMenuContext();

    const { name, subMenu } = group;

    return (
        <SubMenuGroup key={name}>
            {subMenu.map(item =>
                'link' in item ? (
                    <ItemLink item={item} key={item.link} onClick={() => handleItemLinkClick(mainGroupName)} />
                ) : null
            )}
        </SubMenuGroup>
    );
};

interface SideMenuGroupProps {
    readonly group: SubMenuItem;
    readonly isCollapsed?: boolean;
}

const INITIAL_HEIGHT = 14;
const SUBMENU_ITEM_HEIGHT = 34.2;
const GROUP_TITLE_HEIGHT = 34;

const SideMenuGroup: React.FC<React.PropsWithChildren<SideMenuGroupProps>> = ({ group, isCollapsed = true }) => {
    const { handleItemLinkClick } = useSideMenuContext();
    const { name, subMenu } = group;
    const ref = useRef<HTMLDivElement>(null);
    const calculatedHeight = useMemo(() => {
        let sumHeight = INITIAL_HEIGHT;

        subMenu.forEach(item => {
            if ('link' in item) {
                sumHeight += SUBMENU_ITEM_HEIGHT;
            } else {
                sumHeight += SUBMENU_ITEM_HEIGHT * item.subMenu.length + GROUP_TITLE_HEIGHT;
            }
        });

        return sumHeight;
    }, [subMenu]);

    const { collapsibleWrapperStyles } = useCollapsible({
        ref,
        isCollapsed,
        extendedCollapsibleWrapperStyles: closed => ({
            height: closed ? 0 : `${calculatedHeight}px`,
            opacity: '1',
            transition: 'height 0.3s ease-in-out',
        }),
    });

    return (
        <SubMenuGroup key={name} ref={ref} style={collapsibleWrapperStyles}>
            {subMenu.map(item => {
                if ('link' in item)
                    return <ItemLink item={item} key={item.link} onClick={() => handleItemLinkClick(name)} />;
                if ('subMenu' in item) {
                    return (
                        <SubMenuContainer key={item.name}>
                            <SubMenuTitleContainer>
                                <SubMenuTitleLine />
                                <SubMenuTitle>{item.name}</SubMenuTitle>
                                <SubMenuTitleLine isEnding />
                            </SubMenuTitleContainer>
                            <SubGroup group={item} mainGroupName={name} />
                        </SubMenuContainer>
                    );
                }

                return null;
            })}
        </SubMenuGroup>
    );
};

interface SideMenuProps {
    readonly open: boolean;
    readonly closeSideMenu: () => void;
}

export const SideMenu: React.FC<React.PropsWithChildren<SideMenuProps>> = ({ open, closeSideMenu }) => {
    const menuRef = useRef<HTMLDivElement>(null);
    const { data: schema, isError } = useSideMenuSchema();

    useClickOutside(menuRef, open, closeSideMenu);

    const [currentOpenedGroup, setCurrentOpenedGroup] = useState<string | null>(
        localStorage.getItem('SideMenuLastOpenedGroup') ?? null
    );

    const activeItem = findCurrentActiveItem(schema);

    function handleGroupClick(groupName: string): void {
        setCurrentOpenedGroup(currentOpenedGroup === groupName ? null : groupName);
    }

    const handleItemLinkClick = useCallback(
        (name?: string): void => {
            if (name == null) return;

            closeSideMenu();
            saveLastOpenedGroup(name);
            setCurrentOpenedGroup(name);
        },
        [closeSideMenu]
    );

    const providerValue = useMemo(() => ({ activeItem, handleItemLinkClick }), [activeItem, handleItemLinkClick]);

    if (!schema || isError) {
        return null;
    }

    return (
        <SideMenuContext.Provider value={providerValue}>
            <Container data-testid="SideMenu" open={open} ref={menuRef}>
                <Header>
                    <LinkComp url="/backoffice">
                        <TitleIconsContainer onClick={closeSideMenu}>
                            <LemonadeIcon {...iconStyles} />
                            <BlenderIcon />
                        </TitleIconsContainer>
                    </LinkComp>
                    <StyledCloseIcon onClick={closeSideMenu} />
                </Header>

                {schema.map(item => {
                    const { name } = item;
                    const isCurrentOpenedGroup = currentOpenedGroup === name;
                    const ItemIcon = name in SideMenuIconsDictionary ? SideMenuIconsDictionary[name] : null;

                    if ('link' in item) {
                        return (
                            <ItemLink
                                isSingleLinkItem
                                item={item}
                                key={item.link}
                                onClick={() => handleItemLinkClick(name)}
                            />
                        );
                    }

                    if ('subMenu' in item) {
                        return (
                            <React.Fragment key={name}>
                                <GroupItemTitleContainer
                                    onClick={() => handleGroupClick(name)}
                                    open={isCurrentOpenedGroup}
                                >
                                    {ItemIcon && <ItemIcon style={iconStyles} />}
                                    <div>{name}</div>
                                    <StyledArrowIcon height={8} open={isCurrentOpenedGroup} width={8} />
                                </GroupItemTitleContainer>
                                <SideMenuGroup group={item} isCollapsed={!isCurrentOpenedGroup} />
                            </React.Fragment>
                        );
                    }

                    return null;
                })}
            </Container>
        </SideMenuContext.Provider>
    );
};
