import type { SwitchProps as HeadlessSwitchProps } from '@headlessui/react';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import { forwardRef } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Icon } from '../Icon/Icon';
import * as styles from './Switch.css';

export interface SwitchProps extends Omit<HeadlessSwitchProps, 'value'> {
  readonly value: boolean;
  readonly variant?: 'negative' | 'neutral' | 'positive';
  readonly size?: 'md' | 'sm';
  readonly disabled?: boolean;
}

export const Switch = forwardRef<HTMLDivElement, SwitchProps>(
  ({ value, onChange, variant = 'neutral', size = 'sm', disabled, ...props }, ref) => (
    <HeadlessSwitch
      checked={value}
      className={styles.switchContainer({ variant, size })}
      disabled={disabled}
      onChange={onChange}
      {...props}
    >
      <Flex alignItems="center" className={styles.switchThumb({ size })} justifyContent="center" ref={ref}>
        {size === 'md' && value && <Icon color="neutral7" name="check" size="lg" />}
      </Flex>
    </HeadlessSwitch>
  ),
);
