import { CopyIcon, FormInputWrapper } from '@lemonade-hq/bluis';
import { useAnalytics } from '@lemonade-hq/boutique';
import { CopyToClipboard } from '@lemonade-hq/cdk';
import type { Maybe } from '@lemonade-hq/ts-helpers';
import React from 'react';
import styled from 'styled-components';
import type { Rater } from '../queries/useRaters';
import { RatersDialogContent } from './RatersDialogContent';
import { FormWrapper, Input } from './shared';
import { useCurrentPrincipal } from 'components/EnsurePrincipal';

export const StrongParagraph = styled.p`
    font-weight: bold;
    margin-bottom: 0;
`;

interface Credentials {
    readonly username: string;
    readonly password: string;
}

interface ConnectStepProps {
    readonly rater: Maybe<Rater>;
    readonly credentials: Credentials;
}

interface EndIconProps {
    readonly value: string;
    readonly onClick: () => void;
}

const EndIcon: React.FC<React.PropsWithChildren<EndIconProps>> = ({ value, onClick }) => {
    return (
        <CopyToClipboard
            confirmationText="Copied!"
            copyToClipboardText="Copy to clipboard"
            onClick={onClick}
            textToCopy={value}
        >
            <CopyIcon />
        </CopyToClipboard>
    );
};

interface ReadOnlyInputProps {
    readonly value: string;
    readonly name: string;
}

const ReadOnlyInput: React.FC<React.PropsWithChildren<ReadOnlyInputProps>> = ({ value, name }) => {
    const { principal } = useCurrentPrincipal();
    const { trackEvent } = useAnalytics();

    function trackCopy(): void {
        const event = name === 'Username' ? 'username_copied' : 'password_copied';

        trackEvent(event, {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            agent_id: principal?.subject ?? '',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            partner_id: principal?.partnerPublicId ?? '',
        });
    }

    return (
        <FormInputWrapper htmlFor={`connect-rater-form-${name}`} id={`connect-rater-form-${name}`} label={name}>
            <Input
                endIcon={<EndIcon onClick={trackCopy} value={value} />}
                name={name}
                readOnly
                type="text"
                value={value}
            />
        </FormInputWrapper>
    );
};

export const ConnectStep: React.FC<React.PropsWithChildren<ConnectStepProps>> = ({ rater, credentials }) => {
    if (!rater) return null;

    return (
        <RatersDialogContent>
            <StrongParagraph>Here are the credentials to sign in to {rater.name}.</StrongParagraph>
            <p>Once you’ve entered them, click on the “Check Connection” button to make sure you’re all good!</p>
            <FormWrapper>
                <ReadOnlyInput name="Username" value={credentials.username} />
                <ReadOnlyInput name="Password" value={credentials.password} />
            </FormWrapper>
        </RatersDialogContent>
    );
};
