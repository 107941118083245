/* eslint-disable @typescript-eslint/prefer-literal-enum-member */

// common selectors for matching common ui behaviors.
// currently matching `react-aria`, but can change if we change library

const HOVER_SELECTOR = ':is([data-hovered], :hover)';
const FOCUS_VISIBLE_SELECTOR = ':is(:focus-visible)';
const SELECTED_SELECTOR = '[data-selected]';
const FOCUS_SELECTOR = ':is([data-focused], :focus)';
const DISABLED_SELECTOR = ':is([data-disabled], [disabled], :disabled, [data-headlessui-state=disabled])';
const ERROR_SELECTOR = '[data-has-error=true]';
const OPEN_SELECTOR = '[data-open]';
const ACTIVE_SELECTOR = ':is([data-active], :active)';
const ACTIVE_OR_HOVER = ':is(:active, :hover, [data-hovered])';
const IS_CHECKED_OR_INDETERMINATE = ':is([data-state=checked], [data-state=indeterminate])';
const CHECKED = `${IS_CHECKED_OR_INDETERMINATE}:not(${HOVER_SELECTOR}${ACTIVE_SELECTOR})`;
const UNCHECKED_HOVERED = `[data-state=unchecked]${HOVER_SELECTOR}`;
const UNCHECKED_SELECTED = `[data-state=unchecked]${ACTIVE_SELECTOR}`;
const CHECKED_HOVERED = `${IS_CHECKED_OR_INDETERMINATE}${HOVER_SELECTOR}:not(${ACTIVE_SELECTOR})`;
const CHECKED_HOVERED_SELECTED = `${IS_CHECKED_OR_INDETERMINATE}${HOVER_SELECTOR}${ACTIVE_SELECTOR}`;
const SIDE_TOP = '[data-side=top]';
const SIDE_BOTTOM = '[data-side=bottom]';
const COLLAPSABLE_STATE_OPEN = '[data-state=open]';
const COLLAPSABLE_STATE_CLOSED = '[data-state=closed]';
const LOADING = '[data-loading=true]';
const DRAGGING = '[data-dragging=true]';

export enum UIStateSelector {
  Hover = HOVER_SELECTOR,
  FocusVisible = FOCUS_VISIBLE_SELECTOR,
  Focus = FOCUS_SELECTOR,
  FocusedButNotHovered = `${FOCUS_VISIBLE_SELECTOR}:not(${HOVER_SELECTOR})`,
  Selected = SELECTED_SELECTOR,
  Disabled = DISABLED_SELECTOR,
  Error = ERROR_SELECTOR,
  Open = OPEN_SELECTOR,
  Active = ACTIVE_SELECTOR,
  ActiveOrHover = ACTIVE_OR_HOVER,
  Checked = CHECKED,
  UncheckedHovered = UNCHECKED_HOVERED,
  UncheckedSelected = UNCHECKED_SELECTED,
  CheckedHovered = CHECKED_HOVERED,
  CheckedHoveredSelected = CHECKED_HOVERED_SELECTED,
  PopoverAtTop = SIDE_TOP,
  PopoverAtBottom = SIDE_BOTTOM,
  CollapsableStateOpen = COLLAPSABLE_STATE_OPEN,
  CollapsableStateClosed = COLLAPSABLE_STATE_CLOSED,
  Loading = LOADING,
  Dragging = DRAGGING,
}
