import { isDefined } from '@lemonade-hq/ts-helpers';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Collapsible from '@radix-ui/react-collapsible';
import type { RecipeVariants } from '@vanilla-extract/recipes';
import { clsx } from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import type { FC } from 'react';
import type { FlexProps } from '../../base/Flex/Flex';
import { Flex } from '../../base/Flex/Flex';
import { Layout } from '../../base/Layout/Layout';
import { Text } from '../../base/Text/Text';
import { borderRadius as borderRadiusStyles } from '../../theme/radius.css';
import { spacing } from '../../theme/spacing.css';
import type { Status } from '../../theme/status';
import type { IconSize } from '../Icon/Icon';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/types';
import * as styles from './Banner.css';

type BannerBaseProps = Omit<FlexProps, 'borderRadius'> &
  RecipeVariants<typeof borderRadiusStyles> & {
    readonly variant: Status;
    readonly title: JSX.Element | string;
    readonly isCollapsible?: boolean;
    readonly iconName?: IconName;
    readonly iconSize?: IconSize;
    readonly isOpen?: boolean;
  };

const BannerBase: FC<BannerBaseProps> = forwardRef<HTMLDivElement, BannerBaseProps>(
  ({ variant, title, isCollapsible = false, iconName, iconSize = 'lg', isOpen = false }, ref) => (
    <Flex
      alignItems="center"
      className={styles.bannerBase({ isCollapsible })}
      gap={spacing.s08}
      justifyContent="space-between"
      ref={ref}
    >
      <Flex alignItems="center" gap={spacing.s06} width="100%">
        <Layout className={styles.bannerIconWrapper({ variant })} padding={spacing.s02}>
          <Icon name={iconName ?? 'info-circle-solid'} size={iconSize} />
        </Layout>
        <Layout pl={spacing.s02} pr={spacing.s04} width="100%">
          {typeof title === 'string' ? (
            <Text as="span" type="text-md">
              {title}
            </Text>
          ) : (
            title
          )}
        </Layout>
      </Flex>
      {isCollapsible && <Icon className={styles.arrow({ isOpen })} name="chevron-down" size="md" />}
    </Flex>
  ),
);

export type BannerProps = Omit<BannerBaseProps, 'withContent'> & {
  readonly content?: JSX.Element | string;
  readonly className?: string;
  readonly contentClassName?: string;
  readonly withBorder?: boolean;
  readonly withBackground?: boolean;
  readonly floatingContent?: boolean;
  readonly isOpenByDefault?: boolean;
};

export const Banner: FC<BannerProps> = forwardRef<HTMLDivElement, BannerProps>(
  (
    {
      variant,
      title,
      content,
      iconName,
      iconSize,
      className,
      contentClassName,
      withBackground = true,
      withBorder = true,
      borderRadius = 'sm' as const,
      floatingContent = false,
      isOpenByDefault,
      ...props
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(isOpenByDefault);

    const hasContent = isDefined(content) && content !== '';

    useEffect(() => {
      if (isOpenByDefault != null) {
        setIsOpen(isOpenByDefault);
      }
    }, [isOpenByDefault]);

    return (
      <Collapsible.Root asChild onOpenChange={hasContent ? setIsOpen : undefined} open={hasContent && isOpen}>
        <div className={styles.rootContainer}>
          <Flex
            className={clsx(
              borderRadiusStyles({ borderRadius }),
              styles.banner({ variant, withBorder, withBackground, flatBottom: hasContent && isOpen }),
              className,
            )}
            data-testid="banner"
            flexDirection="column"
            ref={ref}
            {...props}
          >
            <Collapsible.Trigger asChild>
              <span>
                <BannerBase
                  iconName={iconName}
                  iconSize={iconSize}
                  isCollapsible={hasContent}
                  isOpen={isOpen}
                  title={title}
                  variant={variant}
                />
              </span>
            </Collapsible.Trigger>
          </Flex>
          {hasContent && (
            <Collapsible.Content asChild>
              <Flex
                className={clsx(
                  borderRadiusStyles({ borderRadius }),
                  styles.banner({ variant, withBorder, withBackground, flatTop: true }),
                  styles.contentWrapper({ isFixed: floatingContent }),
                  contentClassName,
                )}
                data-testid="banner-content"
              >
                {typeof content === 'string' ? (
                  <Text as="span" type="text-md">
                    {content}
                  </Text>
                ) : (
                  content
                )}
              </Flex>
            </Collapsible.Content>
          )}
        </div>
      </Collapsible.Root>
    );
  },
);
