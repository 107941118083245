import { Badge, Card, Flex, Layout, spacing, Text } from '@lemonade-hq/blender-ui';
import { getEnvId } from '@lemonade-hq/bluiza';
import * as Dropdown from '@radix-ui/react-dropdown-menu';
import type { FC } from 'react';
import * as styles from './EnvIdWarning.css';

export const EnvIdWarning: FC = () => {
    const urlEnvId = getEnvId();

    const envId = window.ENV_ID;
    const isEnvMatched = urlEnvId === envId;
    const isStaging = import.meta.env.VITE_ENV_NAME === 'staging' && !window.location.href.includes('localhost');

    if (!isStaging || isEnvMatched) return null;

    return (
        <Layout mr={spacing.s12}>
            <Dropdown.Root>
                <Dropdown.Trigger asChild>
                    <Badge
                        className={styles.trigger}
                        icon="alert-circle-solid"
                        label="blender-frontend mismatch"
                        size="md"
                        stroke
                        variant="negative"
                    />
                </Dropdown.Trigger>
                <Dropdown.Content align="start" sideOffset={4}>
                    <Card borderRadius="sm" className={styles.dropdown} padding="12px">
                        <Flex flexDirection="column" gap={spacing.s12}>
                            <Text type="text-md">
                                <b>
                                    If you don&apos;t have a branch in the blender-frontend service, you can disregard
                                    this message.
                                </b>
                            </Text>
                            <div>
                                <Text type="text-md">
                                    Install reload_blender_nginx command in your .zshrc file (if it&apos;s not already
                                    there).
                                </Text>
                                <Card
                                    borderRadius="sm"
                                    mt={spacing.s08}
                                    padding={spacing.s08}
                                    shadow="none"
                                    variant="secondary"
                                >
                                    <Text className={styles.code} type="text-sm">
                                        cd Lemonade/blender && make install-nginx-scripts
                                    </Text>
                                </Card>
                            </div>
                            <div>
                                <Text type="text-md">Afterward, close and reopen your terminal, then run:</Text>
                                <Card
                                    borderRadius="sm"
                                    mt={spacing.s08}
                                    padding={spacing.s08}
                                    shadow="none"
                                    variant="secondary"
                                >
                                    <Text className={styles.code} type="text-sm">
                                        reload_blender_nginx {urlEnvId}
                                    </Text>
                                </Card>
                            </div>
                        </Flex>
                    </Card>
                </Dropdown.Content>
            </Dropdown.Root>
        </Layout>
    );
};
