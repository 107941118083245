import { clsx } from 'clsx';
import { forwardRef } from 'react';
import type { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import * as styles from './Chip.css';

interface ChipsProps
  extends Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick' | 'onMouseEnter' | 'onMouseLeave'> {
  readonly label?: string;
  readonly interactive?: boolean;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly size?: 'md' | 'sm';
}

export const Chip = forwardRef<HTMLButtonElement | HTMLSpanElement, PropsWithChildren<ChipsProps>>(
  ({ label, interactive = true, disabled, className, size = 'md', children, ...rest }, ref) => {
    if (interactive) {
      const buttonRef = ref as React.RefObject<HTMLButtonElement>;
      return (
        <button
          className={clsx(styles.chip({ interactive, size }), className)}
          disabled={disabled}
          ref={buttonRef}
          type="button"
          {...rest}
        >
          {label}
          {children}
        </button>
      );
    }

    return (
      <span className={clsx(styles.chip({ interactive, size }), className)} ref={ref} {...rest}>
        {label}
        {children}
      </span>
    );
  },
);
