import { forwardRef } from 'react';
import { Layout } from '../../base/Layout/Layout';
import type { BadgeProps } from '../Badge/Badge';
import { Badge } from '../Badge/Badge';

type AiBadgeProps = Omit<BadgeProps, 'iconName' | 'variant'> & { readonly iconName?: 'spark-solid' };

export const AiBadge = forwardRef<HTMLDivElement, AiBadgeProps>((props, ref) => (
  <Layout ref={ref}>
    <Badge {...props} variant="ai" />
  </Layout>
));
