import { ma } from '@lemonade-hq/maschema-schema';
import type { Editor } from '@tiptap/core';
import { useForm } from '../Form/FormContext';
import { FormLayout } from '../Form/FormLayout';
import { generateTypedFormComponents } from '../Form/typeHelpers';
import { IconButton } from '../IconButton/IconButton';
import { Modal } from '../Modal/Modal';
import { toolbarButton } from './RichTextEditor.css';

export const linkSchema = ma.record({
  text: ma.optional.string(),
  url: ma.string(),
});

interface LinkDialogProps {
  readonly onChange: (values: { readonly text?: string; readonly url: string }) => void;
  readonly onClose: () => void;
}

export const LinkModal: React.FC<LinkDialogProps> = ({ onChange, onClose }) => {
  const { InputGroup } = generateTypedFormComponents<typeof linkSchema>();
  const {
    values: formValues,
    validationResults: { valid },
  } = useForm();

  return (
    <Modal
      cta={{ onClick: () => onChange(formValues as { text?: string; url: string }), text: 'Save', disabled: !valid }}
      onClose={onClose}
      size="sm"
      title="Add Link"
    >
      <FormLayout>
        <InputGroup inputComponent="Input" label="Link Text" schemaKey="text" />
        <InputGroup inputComponent="Input" label="Link URL" schemaKey="url" />
      </FormLayout>
    </Modal>
  );
};

export const LinkButton: React.FC<{
  readonly editor: Editor;
  readonly disabled?: boolean;
  readonly values: { readonly text: string; readonly url: string };
  readonly openLinkDialog: (e: React.MouseEvent) => void;
}> = ({ disabled, openLinkDialog }) => {
  return (
    <IconButton
      className={toolbarButton()}
      color="neutral7"
      disabled={disabled}
      icon="link"
      onClick={openLinkDialog}
      variant="inline"
    />
  );
};
