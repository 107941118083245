import { clsx } from 'clsx';
import { forwardRef } from 'react';
import type { ForwardedRef } from 'react';
import * as styles from './Hr.css';

export const Hr = forwardRef(
  ({ className, ...props }: React.HTMLAttributes<HTMLHRElement>, ref: ForwardedRef<HTMLHRElement>) => {
    return <hr className={clsx(styles.hr, className)} ref={ref} {...props} />;
  },
);
