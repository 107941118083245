import type { Node } from 'slate';
import type { SelectedTab } from '../reducers/communication';
import type { AttachmentDTO } from 'components/Attachments/types';
import type { Attachment } from 'models/Attachment';
import type { Recipient } from 'models/Recipient';

export enum CommunicationActions {
    EMAIL_CREATE = 'EMAIL_CREATE',
    EMAIL_SELECT_RECIPIENT = 'EMAIL_SELECT_RECIPIENT',
    EMAIL_REMOVE_RECIPIENT = 'EMAIL_REMOVE_RECIPIENT',
    EMAIL_CHANGE_SUBJECT = 'EMAIL_CHANGE_SUBJECT',
    EMAIL_CHANGE_CONTENT = 'EMAIL_CHANGE_CONTENT',
    EMAIL_SELECT_ATTACHMENTS = 'EMAIL_SELECT_ATTACHMENTS',
    EMAIL_REMOVE_ATTACHMENT = 'EMAIL_REMOVE_ATTACHMENT',
    EMAIL_RESET = 'EMAIL_RESET',
    EMAIL_BODY_RESET = 'EMAIL_BODY_RESET',
    EMAIL_DELETE = 'EMAIL_DELETE',
    NOTE_CHANGE_CONTENT = 'NOTE_CHANGE_CONTENT',
    SET_TAB = 'SET_TAB',
    RESET_STATE = 'RESET_STATE',
    EMAIL_ADD_CC_RECIPIENT = 'EMAIL_ADD_CC_RECIPIENT',
    EMAIL_REMOVE_CC_RECIPIENT = 'EMAIL_REMOVE_CC_RECIPIENT',
    EMAIL_RESET_CC_RECIPIENTS = 'EMAIL_RESET_CC_RECIPIENTS',
    SHOULD_UPDATE_THREAD_OWNER = 'SHOULD_UPDATE_THREAD_OWNER',
}

interface ShouldUpdateThreadOwnerAction {
    readonly type: CommunicationActions.SHOULD_UPDATE_THREAD_OWNER;
    readonly payload: { readonly interactionId: string; readonly shouldUpdateThreadOwner: boolean };
}

interface EmailResetCcRecipientsAction {
    readonly type: CommunicationActions.EMAIL_RESET_CC_RECIPIENTS;
    readonly payload: { readonly interactionId: string };
}

interface EmailAddCCRecipientAction {
    readonly type: CommunicationActions.EMAIL_ADD_CC_RECIPIENT;
    readonly payload: {
        readonly interactionId: string;
        readonly ccRecipient: Recipient;
    };
}

interface EmailRemoveCCRecipientAction {
    readonly type: CommunicationActions.EMAIL_REMOVE_CC_RECIPIENT;
    readonly payload: {
        readonly interactionId: string;
        readonly ccRecipient: Recipient;
    };
}

interface EmailCreateAction {
    readonly type: CommunicationActions.EMAIL_CREATE;
    readonly payload: {
        readonly interactionId: string;
        readonly recipient: Recipient;
        readonly ccRecipients: Recipient[];
        readonly shouldUpdateThreadOwner?: boolean;
    };
}

interface EmailSelectRecipientAction {
    readonly type: CommunicationActions.EMAIL_SELECT_RECIPIENT;
    readonly payload: {
        readonly interactionId: string;
        readonly recipient?: Recipient;
        readonly isFirstResponseScheduled: boolean;
    };
}

interface EmailRemoveRecipientAction {
    readonly type: CommunicationActions.EMAIL_REMOVE_RECIPIENT;
    readonly payload: { readonly interactionId: string; readonly recipient: Recipient };
}

interface EmailChangeSubjectAction {
    readonly type: CommunicationActions.EMAIL_CHANGE_SUBJECT;
    readonly payload: { readonly interactionId: string; readonly subject: string };
}

interface EmailChangeContentAction {
    readonly type: CommunicationActions.EMAIL_CHANGE_CONTENT;
    readonly payload: { readonly interactionId: string; readonly content: Node[] };
}

interface EmailSelectAttachmentsAction {
    readonly type: CommunicationActions.EMAIL_SELECT_ATTACHMENTS;
    readonly payload: { readonly interactionId: string; readonly attachments: (Attachment | AttachmentDTO)[] };
}

interface EmailRemoveAttachmentAction {
    readonly type: CommunicationActions.EMAIL_REMOVE_ATTACHMENT;
    readonly payload: { readonly interactionId: string; readonly attachment: Attachment | AttachmentDTO };
}

interface EmailResetAction {
    readonly type: CommunicationActions.EMAIL_RESET;
    readonly payload: { readonly interactionId: string };
}

interface EmailBodyReset {
    readonly type: CommunicationActions.EMAIL_BODY_RESET;
    readonly payload: { readonly interactionId: string };
}

interface EmailDelete {
    readonly type: CommunicationActions.EMAIL_DELETE;
    readonly payload: { readonly interactionId: string };
}

interface NoteChangeContent {
    readonly type: CommunicationActions.NOTE_CHANGE_CONTENT;
    readonly payload: { readonly content: Node[] };
}

interface SetTab {
    readonly type: CommunicationActions.SET_TAB;
    readonly payload: { readonly tab: SelectedTab };
}

interface ResetState {
    readonly type: CommunicationActions.RESET_STATE;
}

export type CommunicationActionTypes =
    | EmailAddCCRecipientAction
    | EmailBodyReset
    | EmailChangeContentAction
    | EmailChangeSubjectAction
    | EmailCreateAction
    | EmailDelete
    | EmailRemoveAttachmentAction
    | EmailRemoveCCRecipientAction
    | EmailRemoveRecipientAction
    | EmailResetAction
    | EmailResetCcRecipientsAction
    | EmailSelectAttachmentsAction
    | EmailSelectRecipientAction
    | NoteChangeContent
    | ResetState
    | SetTab
    | ShouldUpdateThreadOwnerAction;
