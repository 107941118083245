import { clsx } from 'clsx';
import { forwardRef, Fragment, useRef } from 'react';
import type { FC } from 'react';
import { Flex } from '../../base/Flex/Flex';
import type { TextProps } from '../../base/Text/Text';
import { Text } from '../../base/Text/Text';
import { spacing } from '../../theme/spacing.css';
import type { IconProps } from '../Icon/Icon';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/types';
import { highlightSlider, separator, switchContainer, switchOption, switchOptionLabel } from './LabeledSwitch.css';

export interface LabeledSwitchOption<TId extends boolean | number | string = boolean | number | string> {
  readonly icon?: IconName;
  readonly id: TId;
  readonly label: string;
}

export interface LabeledSwitchProps {
  readonly options: LabeledSwitchOption[];
  readonly value?: LabeledSwitchOption['id'] | undefined;
  readonly onChange: (value: LabeledSwitchOption['id'] | undefined) => void;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly size?: 'md' | 'sm';
  readonly variant?: 'brand' | 'negative' | 'neutral' | 'positive';
}

const textColorByVariant: Record<Exclude<LabeledSwitchProps['variant'], undefined>, TextProps<'span'>['color']> = {
  brand: 'brand',
  negative: 'error',
  neutral: 'primary',
  positive: 'positive',
};

const iconSizeBySize = {
  sm: 'md',
  md: 'lg',
} as const satisfies Record<Exclude<LabeledSwitchProps['size'], undefined>, IconProps['size']>;

export const LabeledSwitch: FC<LabeledSwitchProps> = forwardRef(
  ({ options, value, onChange, size = 'md', variant = 'neutral', className, disabled = false }, ref) => {
    const optionsRefs = useRef<HTMLButtonElement[]>([]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
      const target = e.target as HTMLButtonElement;

      const focusedIndex = Number(target.dataset.optionindex);

      switch (e.key) {
        case 'ArrowLeft': {
          const prevIndex = focusedIndex - 1;

          if (prevIndex < 0) {
            return;
          }

          optionsRefs.current[prevIndex]?.focus();
          break;
        }

        case 'ArrowRight': {
          const nextIndex = focusedIndex + 1;

          if (nextIndex >= options.length) {
            return;
          }

          optionsRefs.current[nextIndex]?.focus();
          break;
        }

        default:
          break;
      }
    };

    return (
      <Flex className={clsx(switchContainer({ size, disabled }), className)} ref={ref}>
        {value != null && <div className={highlightSlider({ variant })} />}
        {options.map(({ icon, id, label }, i) => {
          const isSelected = id === value;
          const isFirst = i === 0;
          const isNotLast = i < options.length - 1;
          const hasIcon = icon != null;

          return (
            <Fragment key={id.toString()}>
              <button
                aria-selected={isSelected}
                className={switchOption({ size })}
                data-optionindex={i}
                disabled={disabled}
                onClick={() => onChange(id)}
                onKeyDown={handleKeyDown}
                ref={(optionRef: HTMLButtonElement) => (optionsRefs.current[i] = optionRef)}
                role="option"
                tabIndex={isFirst ? 0 : -1}
                type="button"
              >
                <Flex alignItems="center" gap={spacing.s02}>
                  {hasIcon && <Icon color="neutral7" name={icon} size={iconSizeBySize[size]} />}
                  <Text
                    className={switchOptionLabel}
                    color={isSelected ? textColorByVariant[variant] : 'secondary'}
                    type={size === 'sm' ? 'label-xs' : 'label-sm'}
                  >
                    {label}
                  </Text>
                </Flex>
              </button>
              {isNotLast && <div className={separator({ size })} />}
            </Fragment>
          );
        })}
      </Flex>
    );
  },
);
