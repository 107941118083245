import { ServiceName } from '@lemonade-hq/bluiza';
import type { Operator, Principal } from '@lemonade-hq/boutique';
import { PrincipalType } from '@lemonade-hq/boutique';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { blenderGeneral } from '../apiClients';

/**
 * This component ensures that the current principal is available in the context.
 * principal is the user logged in, type "workforce" means it's a Lemonade employee.
 * operator is the details of the Lemonade employee logged in.
 */

function useCurrentPrincipalData(): UseQueryResult<Principal> {
    const url = '/api/v1/operators/me';

    return useQuery({
        queryKey: [ServiceName.BlenderGeneral, url],
        queryFn: async () => {
            const response = await blenderGeneral.get<{ readonly data: Principal }>(url);

            return response.data.data;
        },
        staleTime: Number.POSITIVE_INFINITY,
    });
}

function useCurrentOperatorData(enabled: boolean): UseQueryResult<Operator> {
    const url = '/api/v1/operators/legacy_me';

    return useQuery({
        queryKey: [ServiceName.BlenderGeneral, url],
        queryFn: async () => await blenderGeneral.get<{ readonly data: Operator }>(url).then(res => res.data.data),
        staleTime: Number.POSITIVE_INFINITY,
        enabled,
    });
}

interface CurrentPrincipalContextProps {
    readonly principal?: Principal;
    readonly operator?: Operator;
}

const CurrentPrincipalContext = createContext<CurrentPrincipalContextProps | null>(null);

export const CurrentPrincipalProvider: React.FC<PropsWithChildren<CurrentPrincipalContextProps>> = ({
    children,
    principal,
    operator,
}) => {
    const principalProviderValue = useMemo(() => ({ principal, operator }), [operator, principal]);

    return (
        <CurrentPrincipalContext.Provider value={principalProviderValue}>{children}</CurrentPrincipalContext.Provider>
    );
};

export const useCurrentPrincipal = (): CurrentPrincipalContextProps => {
    const principalValue = useContext(CurrentPrincipalContext);

    if (principalValue === null) {
        throw new Error('useCurrentPrincipal must be used within a CurrentPrincipalProvider');
    }

    return principalValue;
};

const EnsurePrincipal: FC<PropsWithChildren> = ({ children }) => {
    const { data: principal } = useCurrentPrincipalData();
    const { data: operator } = useCurrentOperatorData(principal?.type === PrincipalType.Workforce);

    return (
        <CurrentPrincipalProvider operator={operator} principal={principal}>
            {children}
        </CurrentPrincipalProvider>
    );
};

export default EnsurePrincipal;
